.backgroundPrimary {
  background: $black;
}

.backgroundSecondary {
  background: $white;
}

.colorPrimary {
  color: $black;
}

.colorSecondary {
  color: $white;
}

.App {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 20px;

  > .body {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;

    > .heading {
      background: transparent;
      border: 0;
      cursor: pointer;
      font-family: inherit;
      font-size: 40px;
      font-weight: 800;
      letter-spacing: -1.8px;
      padding: 0 10px;
      text-align: center;
    }

    > .content {
      max-width: 800px;
      width: calc(100% - 40px);

      > .spoilerAvoidance {
        color: $white;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding-top: 15px;
        width: fit-content;
      }

      > .favourites {
        text-align: center;

        > .subHeading {
          font-size: 25px;
          font-weight: 800;
          letter-spacing: -1px;
          padding: 30px 0 10px;
        }

        > .list {
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 8px;

          > div {
            align-items: center;
            display: flex;
            gap: 10px;
            max-width: 100%;

            > button {
              background: transparent;
              border: 0;
              cursor: pointer;
              height: 23px;
              min-width: 0;
              padding: 0;

              &:first-of-type {
                flex: 0 1 auto;
                font-size: 18px;
                overflow: hidden;
                text-decoration: underline;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &:last-of-type {
                flex: 1 0 auto;
                font-size: 10px;
              }
            }
          }
        }
      }

      > .episode {
        padding: 25px 0;

        > .controls {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          font-size: 20px;
          gap: 15px;
          justify-content: center;
          max-width: 100%;
          padding-bottom: 15px;

          > select {
            border-radius: 5px;
          }

          > button {
            background: $yellow;
            border: 0;
            border-radius: 5px;
            color: $white;
            cursor: pointer;
            flex-shrink: 0;
            font-family: inherit;
            font-size: 18px;
            padding: 6px 10px;
          }
        }

        > .details {
          align-items: center;
          display: flex;
          gap: 40px;
          min-height: 200px;

          @media only screen and (max-width: 600px) {
            align-items: flex-start;
            flex-direction: column;
            gap: 25px;
          }

          > .text {
            flex: 2;

            > .heading {
              font-size: 28px;
              font-weight: 800;
              letter-spacing: -1.8px;
            }

            > .subHeading {
              font-size: 20px;
              font-weight: 800;
              letter-spacing: -1px;
              padding: 10px 0;
            }

            > .buttons {
              display: flex;
              flex-wrap: wrap;
              gap: 5px;
              margin-top: 15px;

              > button {
                background: transparent;
                border: 0;
                color: $white;
                cursor: pointer;
                flex-shrink: 0;
                font-family: inherit;
                font-size: 15px;
                font-weight: bold;
                padding: 0;

                &:disabled {
                  opacity: 0.4;
                }
              }
            }

            > .rating {
              font-weight: bold;
              padding: 10px 0;
            }
          }

          > .poster {
            flex: 1;

            > img {
              border-radius: 5px;
              height: 100%;
              object-fit: contain;
              object-position: left;
              width: 100%;

              @media only screen and (max-width: 600px) {
                height: 169px;
              }

              &.inFlight {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  > .footer {
    padding-bottom: 20px;
    text-align: center;
  }
}

.react-autosuggest__container {
  margin-top: 30px;
  position: relative;
  width: 100%;
}

.react-autosuggest__container--open[data-disabled='true'] > div > ul > li:hover {
  background: $black;
  color: $white;
  cursor: unset;
}

.react-autosuggest__suggestions-container--open {
  border: 1px solid $grey;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top: 0;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.react-autosuggest__input {
  background: $black;
  border: 1px solid $grey;
  border-radius: 25px;
  box-sizing: border-box;
  color: $white;
  font-size: 20px;
  padding: 10px 20px;
  width: 100%;

  &.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.react-autosuggest__input--focused {
    outline: none;
  }
}

.react-autosuggest__suggestion {
  align-items: center;
  background: $black;
  color: $white;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  height: 100px;
  padding: 20px;

  &:last-of-type {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  &.react-autosuggest__suggestion--highlighted {
    background-color: $lightgrey;
    color: $black;
  }

  > .poster {
    padding-right: 10px;
    width: 70px;

    > img {
      border-radius: 5px;
      width: 100%;
    }
  }
}
